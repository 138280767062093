/* poppins font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* orbitron font */
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

/* vendin font */
@font-face {
    font-family: 'vendin';
    src: url('../fonts/Vendin.ttf');
}

:root {
  --body_bg: #090909;
  --gradientLeft: #6e4794;
  --gradientRight: #8b36e0;
  --primary_Gradient: linear-gradient(
    to right,
    var(--gradientLeft),
    var(--gradientRight)
  );
  --white: #fff;
  --black: #000;
  --secondary_white : #9D9D9D;
  --button_shadow : #2E2734;
  --creator_cardBg : #141414;
  --pagination_bullet : #161616;
  --accord_gradLeft : #0D0A0F;
  --accord_gradRight : #0F0C12;
  --accord_gardient : linear-gradient(to right,var(--accord_gradLeft),var(--accord_gradRight));
  --accord_borderLeft : #0B090D;
  --accord_borderRight : #8D8C8E;
  --accord_borderGrad : linear-gradient(to right,var(--accord_borderLeft),var(--accord_borderRight));
  --weight_four: 400;
  --weight_five: 500;
  --weight_six: 600;
  --weight_seven: 700;
  --weight_eight: 800;
  --weight_nine: 900;
  --small_font: 8px;
  --lower_fontsize: 10px;
  --font_eleven: 11px;
  --font_twelve: 12px;
  --font_thirteen: 13px;
  --font_fifteen: 15px;
  --font_sixteen: 16px;
  --font_eighteen: 18px;
  --font_twenty: 20px;
  --font_twentyfive: 25px;
  --higher_fontsize: 30px;
  --font_thirtyfive: 35px;
  --font_fourty: 40px;
  --font_fourtyfive: 45px;
  --font_fifty: 50px;
}

/* common css */
.poppins{
    font-family: "Poppins", sans-serif !important;
}
.orbitron{
    font-family: "Orbitron", sans-serif !important;
}
.vendin{
    font-family: 'vendin' !important;
}
.pointer{
    cursor: pointer !important;
}
/* .common_bg{
    background-color: var(--body_bg);
} */
.higher_tootop{
    margin-top: 150px;
}
.higher_top{
    margin-top: 100px;
}
.selecttext div{
    color: #fff !important;
}
.ql-toolbar.ql-snow, .ql-container.ql-snow{
    border: 1px solid var(--violentclr) !important;
}

::-webkit-scrollbar {
    width: 3px;
    height: 7px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #2e2e2e;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--gradientRight); 
    border-radius: 10px;
  }
  
/* end of common css */

.formlist{
    list-style-type: none;
    gap: 20px;
    justify-content: center;
  }
  .formlist li{
    color: #fff;
    font-family: 'vendin' !important;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    width: 130px;
    min-width: 130px;
    text-align: center;
  }
  .formlist li:hover,  .formlist li.active{
    background-color: var(--violentclr);
  }
  .eyelist{
    position: absolute;
    right: 10px;
    /* top: 25%; */
    top: 12px;
    cursor: pointer;
  }
  .eyes{
    color: var(--violentclr);
    font-size: 27px;
  }
  .otpdiv div{
    justify-content: center;
    gap: 5px;
    margin-bottom: 4%;
  }
  .otpdiv div input{
    width: 3.5em !important;
    height: 3.5em;
    border: 1px solid #7B3FB7;
    background: transparent;
    color: #fff;
  }
  .counts{
    color: #fff;
  }
  #privy-dialog-title, #privy-modal-content a{
    color: #fff;
  }
  #privy-modal-content{
    border: 1px solid var(--modalborder);
    background: var(--modalbackclr);
    color: #fff;
    font-family: "Poppins", sans-serif !important;
  }
  #privy-modal-content #connect-text {
    color: #ffff;
}
  #privy-modal-content button:hover {
    background-color: var(--violentclr);
    border-color: var(--violentclr) !important;
    color: #ffff;
  }
  #privy-modal-content input[type="email"] {
      background-color: transparent !important;
  }
  #privy-modal-content input:focus {
    border-color:  var(--violentclr) !important;
}
/* header css */
.header{
    position: fixed;
    top: 0;
    z-index: 777;
    width: 100%;
    background-color: transparent;
    /* background-color: var(--body_bg); */
}
.header__logo{
    /* height: 50px; */
    width: 100%;
    max-width: 280px;
}
.header__links{
    text-decoration: none;
}
.header__linksHolder :is(ul,li){
    padding-left: 0;
    list-style-type: none;
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
    cursor: pointer;
}
.header__linksHolder li:hover{
    color: var(--gradientLeft);
}
.header__connectBtn{
    border: 1px solid var(--gradientLeft);
    outline: none;
    padding: 7px 15px;
    background-image: var(--primary_Gradient);
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
}
.header__connectBtn.net{
    padding: 5px 7px !important;
}
.header__connectBtn svg{
    height: 20px;
    width: 25px;
    fill: var(--white);
}
.netimg{
    width: 28px;
    height: 28px;
}
.walletdrop.netdrop .dropdown-menu a{
    padding: 4px 4px;
    margin-bottom: 2%;
}
.walletdrop.netdrop .dropdown-menu a:hover h6 {
  color: var(--violentclr);
}
.header__connectBtn:hover{
    background-color: transparent;
    background-image: none;
    color: var(--gradientRight);
}
.header__links{
    text-decoration: none;
}
.header__hamburger{
    border: 1px solid var(--gradientLeft);
    outline: none;
    padding: 7px 12px;
    background-image: var(--primary_Gradient);
}
.header__hamburger svg{
    fill: var(--white);
}
.header__canvas.offcanvas{
    background-color: var(--body_bg);
}
.header__transBtn{
    border: none;
    outline: none;
    background-color: transparent;
}
.header__transBtn svg{
    fill: var(--white);
    height: 25px;
    width: 25px;
}
/* end of header css */

/* home css */
.home__bannerTitle{
    color: var(--white);
    font-size: var(--font_fifty);
    font-weight: var(--weight_five);
}
.home__bannerSubTitle{
    color: var(--white);
    font-size: var(--higher_fontsize);
    font-weight: var(--weight_five);
}
.home__bannerHint{
    color: var(--secondary_white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_five);
}
.home__bannerNetSec{
    background-image: url('../Images/net.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 200px;
}
.home__outlineBtn{
    border: 2px solid var(--body_bg);
    outline: 1px solid var(--gradientLeft) ;
    background-image: var(--primary_Gradient);
    color: var(--white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_five);
    padding: 12px 35px;
    box-shadow: 1px 11px 17px 0px var(--button_shadow);
    transition: .3s all linear;
}
.home__outlineBtn:hover{
    transform: scale(.95);
}
.home__btnOutlineHider{
    border: none !important;
}
.home__modalAligner{
    top: -40px;
}
.home__marqueeImg{
    max-width: 65% !important;
}
.home__midModalImg{
    transform: scale(1.1);
}
.home__counterTitle{
    color: var(--white);
    font-size: var(--higher_fontsize);
    font-weight: var(--weight_seven);
}
.home__counterSubitle{
    color: var(--white);
    font-size: var(--font_eighteen);
    font-weight: var(--weight_five);
}
.home__counterHint{
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
}
.swiper.home__gameSwiper{
    padding-bottom: 40px;
}
.home__gameSwiper .swiper-pagination-bullet{
    background-color: var(--pagination_bullet);
    opacity: 1;
}
.home__gameSwiper .swiper-pagination-bullet-active{
    background-color: var(--gradientRight);
}
.home__faqHint{
    color: var(--white);
    font-size: var(--font_twelve);
    font-weight: var(--weight_four);
    line-height: 2.5;
}
.home__faqAccord .accordion-header,.home__faqAccord .accordion-header,.home__faqAccord .accordion-item{
    background-color: transparent !important;
    border-radius: 10px !important;
}
.home__faqAccord .accordion-body{
    background: #18171b !important;
    border-radius: 6px;
    margin-top: 10px;
    box-shadow: 0px 0px 11.89px 0px #0000000D;
}

.home__faqAccord .accordion-item{
    border: none !important;
}
.home__faqAccord .accordion-header button{
    border:  solid;
  border-image-slice: 1;
  border-width: 1px;
  border-image-source: var(--accord_borderGrad);
  border-radius: 10px !important;

}
.home__faqAccord .accordion-header button:not(collapsed){
    outline: none !important;
    box-shadow: none !important;
    color: var(--white);
    font-size: var(--font_thirteen);
    border-radius: 10px !important;
    border: 1px solid #141414;  
    /* background-color: transparent !important;  */
    background: #141414 !important;

    background-image: none !important;
}
.home__faqAccord .accordion-header button.collapsed{
    border: 1px solid #141414;  
    /* background-color: transparent !important;  */
    background: #141414 !important;

    background-image: none !important;
  outline: none !important;
    box-shadow: none !important;
    color: var(--white);
    font-size: var(--font_thirteen);
    border-radius: 10px !important;
}
.home__faqAccord .accordion-header button.collapsed::after{
    background-image: url('../Images/plus.svg');
    background-size: 100% 100%;
    display: none;
}
.home__faqAccord .accordion-header button::after{
    background-image: url('../Images/minus.svg');
    background-size: 100% 100%;
    display: none;
}
/* end of home css */
/* creator card css */
.createCard{
    background-color: var(--creator_cardBg);
    border: 1px solid var(--gradientRight);
    cursor: pointer;
    transition: 0.5s;
}
.createCard:hover{
    filter: drop-shadow(2px 4px 6px var(--violentclr));
    transition: 0.5s;
}
.creatorCard__title{
    color: var(--white);
    font-size: var(--font_eighteen);
}
.creatorCard__hint{
    color: var(--secondary_white);
    font-size: var(--font_fifteen);
    font-weight: var(--weight_four);
}
.creatorCard__img{
    height: 60px;
    width: 60px;
}
/* end pf creator card css */

/* footer css */
.footer__communityTitle{
    color: var(--white);
    font-size: var(--font_eighteen);
    font-weight: var(--weight_five);
}
.footer__socialLinksHolder :is(ul,li){
    list-style-type: none;
    padding-left: 0;
    color: var(--white);
    fill: var(--white);
    font-size: var(--font_twelve);
    font-weight: var(--weight_five);
    width: fit-content;
}
.footer li:hover{
    color: var(--gradientRight);
}
.footer__socialLinksHolder svg, .socialimg{
    height:24px;
    width:24px;
    object-fit: contain;
    aspect-ratio: 3/2;
}

.footer__socialLinksHolder svg:hover{
    fill: var(--gradientRight);
}
.footer__logo{
    height: 40px;
    /* width: 60px; */
    width: 100%;
}
.footer__copyrights{
    color: var(--white);
    font-size: var(--font_thirteen);
    font-weight: var(--weight_four);
}
.footer__rightTitle{
    color: var(--white);
    font-size: var( --font_sixteen);
    font-weight: var(--weight_five);
}
.footer__gridHolder{
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(150px,1fr));
    gap: 20px;
}
/* end of footer css */


.up_img{
    width: 150px;
    height: 150px;
    object-fit: cover;
}

/* newlines css start */

.content {
    color: var(--secondary_white);
    font-size: 14px;
    margin-bottom: 0;
    font-weight: 500;
    line-height: 26px;
}

.logintop {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10vh;
}

.logintop__loginbox {
    background-color: #141414;
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 24px;
    border-radius: 7px;
}

.logintop__h4 {
    color: var(--white);
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 7px;
}

.logintop__formbox .form-control {
    background-color: #2D2D2D;
    border: 0px;
    border-radius: 3px;
    font-size: 15px;
    height: 45px;
    color: #868686;
}
.logintop__formbox .form-control:focus {
    box-shadow: none;
}
.logintop__formbox .form-control::placeholder {
    color: #868686;
}
.logintop__formbox .input-group .form-control {
    background-color: transparent;
}
.logintop__formbox .input-group {
    background-color: #2D2D2D;
    border-radius: 3px;
}

.logintop__formbox .input-group .input-group-text {
    background-color: transparent;
    border: 0px;
    padding-right: 4px;
    color: #fff;
    font-size: 18px;
}

.logintop__formbox .input-group .input-group-text.rtext {
    padding-right: 12px;
    border-left: 1px solid #383838;
}

.logintop__formbox .altbtn {
    padding: 0;
    border: 0px;
    outline: 0px;
    font-size: 14px;    
    line-height: inherit;
    color: #fff;
    font-weight: 500;
}

.logintop__formbox .socialbox {
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: center;
}

.logintop__formbox .socialicon {
    background-color: #2D2D2D;
    width: 45px;
    height: 45px;
    display: grid;
    place-content: center;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
}

.logintop__formbox .socialbox a {
    text-decoration: none;
}

.logintop__formbox .orline {
    text-align: center;
    margin: 24px 0px;
}
.logintop__formbox .orline div {
    height: 1px;
    background-color: #383838;
    width: 50%;
    margin: auto;
}
.logintop__formbox .orline span {
    color: #fff;
    font-size: 14px;
    font-weight: var(--weight_five);
    text-transform: uppercase;
    letter-spacing: 1px;
    display: block;
    width: 45px;
    margin: -12px auto 0px;
    background-color: #141414;
    padding: 0px 7px;
}

.bglightbox {
    background-color: #141414;
    display: inline-block;
    padding: 7px 16px;
    border-radius: 4px;
}

.flex {
    display: flex;
    align-items: center;
}

.bglightbox .rtext {
    color: #808080;
    font-size: 15px;
    font-weight: var(--weight_seven);
    text-transform: uppercase;
}

.flexbox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.bglightbox .form-check .form-check-input {
    border: 1px solid #fff;
    background-color: transparent;
    border-radius: 3px;
}

.bglightbox .form-check .form-check-input:checked {
    background-color: #833BCC;
    border-color: #833BCC;
}

.bglightbox .form-check .form-check-input:focus {
    box-shadow: none;
}

.bglightbox .dropdown .dropdown-toggle {
    background-color: transparent;
    padding: 0;
    border: 0px;
    outline: 0;
}

.bglightbox .dropdown .dropdown-toggle::after {
    margin-left: 7px;
}

.bglightbox .dropdown .dropdown-toggle:focus {
    box-shadow: none;
}

.topopt>div:last-child {
    flex-grow: 1;
}

.bglightbox .sicons span {
    color: #fff;
    font-size: 22px;
    cursor: pointer;
    display: inline-block;
}

.bglightbox .react-switch {
    border: 1px solid #808080;
    border-radius: 50px !important;
}

.showtable .table {
    vertical-align: middle;
}

.showtable thead th {
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
}

.showtable thead th, .showtable tbody tr td {
    padding: 15px 10px;
    white-space: nowrap;
}
.showtable tbody tr {
    cursor: pointer;
}
.showtable tbody tr:hover {
    background-color: #141414;
}
.showtable tbody tr td {
    background-color: transparent;
    border-color: #2D2D2D;
}

.showtable tbody tr td .tabcardimg {
    width: 100%;
    max-width: 40px;
    border-radius: 6px;
}

.filterback .accordion-button {
    background-color: transparent;
    font-size: 15px;
    color: #fff;
    font-weight: var(--weight_seven);
    text-transform: uppercase;
    padding: 16px 5px;
}

.filterback .accordion-button:not(.collapsed) {
    box-shadow: none;
}

.filterback .accordion-button:focus {
    box-shadow: none;
}

.filterback .accordion .accordion-body {
    padding: 10px 5px;
}

.filterback .accordion-item {
    background-color: transparent;
    border: 0px;
}

.filterback .accordion-item .accordion-button::after {
    filter: brightness(100);
    transform: rotate(-90deg);
    width: 18px;
    height: 18px;
    background-size: 18px;
}

.filterback .accordion-item .accordion-button:not(.collapsed):after {
    transform: rotate(0);
}

.filterback .accordion .form-control {
    background-color: #2D2D2D;
    border: 0px;
    color: #fff;
    height: 35px;
    font-size: 15px;
    font-weight: var(--weight_six);
    border-radius: 4px;
}

.filterback .accordion .form-control:focus {
    box-shadow: none;
}

.filterback .accordion .form-control::placeholder {
    color: #868686;
}

.filterback .accordion .filtext {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    text-transform: capitalize;
}

.filterback .accordion .filtext:not(:last-child) {
    margin-bottom: 10px;
}

.filterback .accordion .bglightbox {
    background-color: #2D2D2D;
    display: block;
    margin-top: 14px;
    padding: 10px 12px;
    cursor: pointer;
}

.cardlist .header__connectBtn {
    min-width: fit-content;
}

.filterdatabox {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 18px;
}

.verifybox {
    position: relative;
}

.verifyicon {
    color: #0d6efd;
    font-size: 22px;
    position: absolute;
    right: 10px;
    top: 2px;
}

.verifyicon.reject {
    color: 	#c6131b;
}
.verifyicon.pending {
    color: lightyellow;
}
.showtable .verifyicon {
    font-size: 20px;
    right: 15px;
    top: -14px;
}

.showtable .table thead {
    position: sticky;
    top: 0px;
    z-index: 9;
}

.showtable .table thead th {
    background-color: #090909;
}

.showtable .table-responsive {
    overflow: auto;
    max-height: 1200px;
}

.centerbox {
    background-color: #292929;
    width: 200px;
    height: 200px;
    border-radius: 20px;
    display: grid;
    place-content: center;
    margin: auto;
}

.centerimg {
    width: 100%;
    max-width: 120px;
}

.centerboxrow {
    margin-top: 80px;
}

.centerboxrow>div:nth-child(-n+4) {
    margin-bottom: 150px;
}

.home__bannerSec .borders, .home__bannerSec .borders img {
    border-radius: 20px;
}

.bglightbox.cdet {
    margin-bottom: 30px;
    padding: 14px 18px;
    display: block;
    border-radius: 10px;
}

.instrctborder.history {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.violettext  {
    color: var(--gradientRight);
    font-weight: var(--weight_five);
}

.footer .paylinks li:hover {
    color: #fff;
}

.supporttable .table {
    background-color: #090909;
}












/* newlines css end */



/* media queries */

@media (min-width: 1800px) {
    .filterdatabox {
        grid-template-columns: repeat(4, 1fr);
    }

    .centerbox {
        width: 250px;
        height: 250px;
    }

    .centerimg {
        max-width: 150px;
    }
}

@media (max-width: 1300px) {
    .filterdatabox {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (1200px <= width <= 1300px) {
    .showtable .verifyicon {
        right: 8px;
    }
}
@media (991px <= width <= 1199px) {
    .showtable .verifyicon {
        right: -6px;
    }

    .header .container {
        max-width: 98% !important;
    }
}

@media(991px <= width <= 1300px){
    .mt15 {
        margin-top: 15px;
    }
}

@media(width < 992px){
    .higher_tootop{
        margin-top: 0px;
    }
    .header__logo {
        max-width: 260px;
    }
    .centerboxrow>div:nth-child(-n+4) {
        margin-bottom: 0px;
    }
    .centerboxrow>div:not(:last-child) {
        margin-bottom: 50px;
    }

    .order-6 {
        order: 6;
    }
    .centerboxrow {
        margin-top: 30px;
    }

    .home__bannerSec .home__bannerSubTitle, .home__bannerSec .home__bannerHint {
        text-align: center;
    }

    .home__bannerSec .centerboxrow .home__outlineBtn {
        display: block;
        margin: auto;
    }
}
@media(width < 800px){
    .showtable .verifyicon {
        right: -5px;
    }
}
@media(width < 767px){
    .flexbox {
        flex-direction: column;
        align-items: normal;
    }
    .topopt>div:last-child {
        text-align: start;
    }
    .filterdatabox {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media (576px <= width < 768px){
    .home__bannerTitle{
        font-size: var(--font_fourty);
    }
    .home__counterTitle{
        font-size: var(--font_eighteen);
    }
    .home__counterSubitle{
        font-size: var(--font_thirteen);
    }
    .home__counterHint{
        font-size: var(--lower_fontsize);
    }
}
@media (width < 576px){
    .home__bannerTitle{
        font-size: var(--higher_fontsize);
    }
    .home__bannerSubTitle{
        font-size: var(--font_twenty);
    }
    .header__connectBtn{
        padding: 8px 8px 7px 8px;
    }
    .showtable thead th, .showtable tbody tr td {
        padding: 15px 22px;
    }
    .header__logo {
        max-width: 200px;
    }
    .cardlist .header__connectBtn {
        padding: 7px 15px;
    }

    .showtable .header__connectBtn {
        padding: 8px 18px;
    }

    .react-stars {
        display: flex;
    }

    .centerboxrow {
        margin-top: 10px;
    }

    .home__bannerSec .borders img {
        width: 100%;
        max-width: 270px;
    }

    .centerbox {
        width: 175px;
        height: 175px;
    }

    .centerimg {
        max-width: 100px;
    }

    .instrctborder.history {
        grid-template-columns: repeat(1, 1fr);
        text-align: center;
    }

    .closebtn {
        right: -2%;
        top: -1%;    
    }
}

@media (max-width: 320px) {
    .header__logo {
        max-width: 160px;
    }
}

/* end of media queries */

/* sakthi changes */

.explore_gameFilter .label_txt {
    font-size: 15px;
    color: #fff;
    font-weight: var(--weight_seven);
    padding: 16px 5px 5px;
}

.explore_gameFilter .css-1dimb5e-singleValue {
color: #fff !important;
}