:root{
  --modalborder: #7B3FB7;
  --modalbackclr: #141414;
  --placeholder: #474747;
  --inputborder: #9c7bbabf;
  --inputbackclr: #141414;
  --violentclr: #7C3EBB;
  --cardback:#141414;

}
.imgw{
  width: 52px;
  height: 52px;
  object-fit: contain;
}
.buyrefund{
  border: 1px solid var(--violentclr);
  background: var(--violentclr);
  border-radius: 10px;
  width: 200px;
  margin: auto;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
}
.imgupload input{
  opacity: 0;
}
.imgupload button {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.imgset{
  width: 120px;
  height: 120px;
  object-fit: cover;
}
.imgw{
  width: 52px;
  height: 52px;
  object-fit: contain;
}
.item_p{
  font-family: "Poppins", sans-serif !important;
}
.wimg{
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.item_select{
  background-color: #000 !important;
  border: 1px solid var(--violentclr);
  color: #fff;
  padding: 6px;
} 
.item_select option{
  background-color: #000 !important;
  border: 1px solid #000 !important;
  color: #fff !important;
}
.active>.page-link, .page-link.active{
  background-color: var(--violentclr) !important;
  border-color: var(--violentclr) !important;
}
.pagination{
  background-color: #000 !important;
  /* background: transparent !important; */
  border-color:  var(--violentclr) !important;
  margin-top: 15px;
}
.page-link{
  background-color: #000 !important;
  color: #fff !important;
  border-color:  var(--violentclr) !important;
}
.page-link:hover{
  background-color: var(--violentclr) !important;
  color: #fff !important;

}
.pos{
  position: relative;
  z-index: 4;
}
.pasteicon{
  position: absolute;
  right: 8px;
  fill: #fff;
  top: 16px;
  cursor: pointer;
}


.markread{
  display: flex;
  gap: 4px;
  color: #fff;
  margin-top: 5px;
  cursor: pointer;
}
.markread svg{
  fill: #fff;
  width: 16px;
}

body {
  background: rgb(9,9,9) url("./Assets/Images/bgimg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.Toastify__toast.Toastify__toast-theme--light{
  background-color: #18171B !important;
  color: #fff !important;
  border: 1px solid #000 !important;
  font-weight: 600;
}
.filterback{
  background: #161516;
}
.gamebtn, .gamebtn:hover,.gamebtn:first-child:active,.gamebtn.show{
  background: transparent;
  border: transparent;
}
.gamemenu{
  width: 300px;
}
.gamemenu a{
   color: #fff;
}
.gamemenu a:hover{
  color: var(--violentclr);
}
.all{
  color: #833BCC;
}
.brbb{
  border-bottom: 1px solid gray;
  padding-bottom: 15px;
}
.popular{
  background: #2D2D2D;
  color: #fff;
  cursor: pointer;
}
.popular:hover {
  background: var(--primary_Gradient);
}
.popularflex{
  display: flex;
  flex-wrap: wrap;
  gap: 14px;
}
.home_banner{
  position: relative;
  z-index: 4;
  background: url("./Assets/Images/banner_section.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;  
  overflow: hidden;
}
.underworld{
  position: absolute;
  width: 100%;
  z-index: -1;
  bottom: -38%;
}
.bliss{
  position: absolute;
  left: 2%;
  width: 280px;
  bottom: 5%;
  z-index: -1;
}
.bliss1{
  position: absolute;
  right: 38%;
  width: 280px;
  bottom: 25%;
  z-index: -1;
}
.shake{
  position: absolute;
  right: 30%;
  width: 200px;
  top: 10%;
  opacity: 0.6;
}
.spaceman{
  position: absolute;
  z-index: -1;
  width: 250px;
  transform: translate(100%, -50%);
    bottom: -8%;
    right: 20%;
}
.glitch{
  position: absolute;
  z-index: -1;
  bottom: 5%;
  width: 230px;
  right: 0;
}
.glitchup{
  position: absolute;
  z-index: -1;
  top: 12%;
  left: 0%;
  width: 230px;
}
.fillcolor{
  /* color: var(--violentclr); */
}
.header.fixedTop{
  background-color: #000;
}
.homeheader .header{
  /* -webkit-animation-name: fadeInDown; */
  animation: fadeInDown;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.3s;
}
.underworld{
  /* -webkit-animation-name: fadeInUp; */
  animation: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  transition: all 0.3s;
}
.spaceman {
  animation: fadeInUptwo;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-delay: 1s;
  animation-fill-mode: both;
  transition: all 0.3s;
}
/* .spaceani{
  -webkit-animation-name: fadeInUps;
  animation-name: fadeInUps;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
} */
@-webkit-keyframes fadeInDown {
  0% {
     /* opacity: 0; */
     -webkit-transform: translateY(-75px);
  }
  100% {
     /* opacity: 1; */
     -webkit-transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
     /* opacity: 0; */
     transform: translateY(-75px);
  }
  100% {
     /* opacity: 1; */
     transform: translateY(0);
  }
}
@-webkit-keyframes fadeInUp {
  0% {
     /* opacity: 1; */
     -webkit-transform: translateY(250px);
  }
  100% {
     /* opacity: 1; */
     -webkit-transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
     /* opacity: 1; */
     transform: translateY(250px);
  }
  100% {
     /* opacity: 1; */
     transform: translateY(0);
  }
}

@-webkit-keyframes fadeInUptwo {
  0% {
    -webkit-transform: translate(100%, 100%);
     /* -webkit-transform: translateY(100%); */
  }
  100% {
    -webkit-animation: translate(100%, -50%);
     /* -webkit-transform: translateY(-50%); */
  }
}

@keyframes fadeInUptwo {
  0% {
    transform: translate(100%, 100%);
     /* transform: translateY(100%); */
  }
  100% {
    transform: translate(100%, -50%);
     /* transform: translateY(-50%); */
  }
}

@-webkit-keyframes fadeInUps {
  0% {
     opacity: 0;
     -webkit-transform: translateY(10px);
  }
  100% {
     opacity: 1;
     -webkit-transform: translateY(0);
  }
}

@keyframes fadeInUps {
  0% {
     opacity: 0;
     transform: translateY(10px);
  }
  100% {
     opacity: 1;
     transform: translateY(0);
  }
}
.sec_glitch{
  position: absolute;
  top: 0;
  left: 0;
  width: 250px;
  z-index: -1;
}
.sec_glitch1{
  position: absolute;
  bottom: 50%;
  right: 0;
  width: 250px;
  z-index: -1;
}
.sec_glitch_l{
  position: absolute;
  top: 0;
  right: 0;
  width: 250px;
  z-index: -1;
}
.blur,.img_sec,.swipe_sec{
  overflow: hidden;
}
.blur::after, .img_sec::after,.swipe_sec::after{
  background: #a259ff80;
  content: "";
  filter: blur(60px);
  height: 430px;
  opacity: .3;
  position: absolute;
  right: 0;
  top: 250px;
  transform: rotate(178deg);
  width: 150px;
  z-index: -1;
}
.bclr{
  border-radius: 0px 4px 4px 0px;
  background: linear-gradient(212.2deg, #7D3EBC 3.58%, #7841AF 99.36%);
  width: 48px;
  height: 48px;
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
}
.swipe_sec::after{
  height: 230px !important;
}
.innerpages {
  position: relative;
}
.innerpages .container {
  position: relative;
  z-index: 9;
}
.blur::before,.item_gold::before, .innerpages::before, .innerpages::after {
  background: #a259ff80;
  content: "";
  filter: blur(60px);
  height: 430px;
  opacity: .3;
  position: absolute;
  left: 0;
  bottom: 20%;
  transform: rotate(178deg);
  width: 150px;
  z-index: -1;
}
.innerpages::before, .innerpages::after {
  z-index: 3;
  bottom: auto;
  top: 30%;
  transform: none;
}
.innerpages::after {
  bottom: 14%;
  top: auto;
  left: auto;
  right: 0px;
}
.widestar{
  position: absolute;
  top: 50%;
  left: 0;
  width: 100px;
  z-index: -1;
}
.item_back{
 background-color: #18171B;
 border: 1px solid #7A40B4;
}
.borders{
  border: 2px solid #7A40B4;
  border-radius: 12px;
  overflow: hidden;
}
.borders img{
  border-radius: 12px;
  transition: 0.5s;
}
.borders:hover img{
  transform: scale(1.1);
  transition: 0.5s;
}
.dinoimg{
  height: 720px;
  object-fit: cover;
  min-height: 720px;
}
.smallimg{
  height: 340px;
  object-fit: cover;
  min-height: 340px;
}
.barbieimg{
  height: 350px;
  object-fit: cover;
  width: 100%;
  min-height: 350px;
}

/* stepper */
.MuiStepLabel-label{
  color: var(--white) !important;
  font-family: "Poppins", sans-serif !important;
}
svg.Mui-completed, svg.Mui-active {
  color: #4caf50 !important;
}
.MuiPaper-elevation{
  border: 1px solid var(--violentclr) !important;
  background: #000 !important;
}
.MuiPaper-elevation .MuiTypography-root{
  color: var(--white);
  font-family: "Poppins", sans-serif !important;
}
.MuiStepConnector-lineHorizontal {
  border-color: #DEBDFF !important;
  border-top-width: 6px !important;
  border-radius: 16px;
}
.MuiStepIcon-root{
  color: #DEBDFF !important;
}
.MuiStepIcon-text {
  fill: #000 !important;
  font-family: "Poppins", sans-serif !important;
}
.MuiButton-containedPrimary,.MuiButton-colorPrimary,.css-1f5ro5o-MuiButtonBase-root-MuiButton-root, .css-18st3fs-MuiButtonBase-root-MuiButton-root{
  border: 1px solid var(--gradientLeft) !important;
  outline: none;
  padding: 7px 15px !important;
  background-image: var(--primary_Gradient) !important;
  color: var(--white) !important;
  font-size: var(--font_fifteen) !important;
  font-weight: var(--weight_five) !important;
  font-family: "Poppins", sans-serif !important;
}
/* stepper */


#privy-modal-content input[pattern="[0-9]"], #privy-modal-content input[id="phone-number-input"], #privy-modal-content select {
  background-color: transparent !important;
  color: #fff !important;
}
#privy-modal-content select option  {
  background-color: #000 !important;
  color: #fff !important;
}


a{
  text-decoration: none;
}
.walletborder{
  border: 1px solid var(--violentclr);
  cursor: pointer;
}
.walletborder:hover{
  filter: drop-shadow(2px 4px 6px #7c3ebb8a);
}
.walletdrop .dropdown-toggle:hover ,.walletdrop .dropdown-toggle:focus, .walletdrop .btn:first-child:active{
  background-color: var(--violentclr) !important;
  border: 1px solid var(--violentclr) !important;
  background-image: unset !important;
}
.walletdrop .dropdown-toggle::after{
  color: #fff !important;
}
/* .walletdrop .dropdown-toggle:hover h6{
  color: var(--white) !important
} */
.walletdrop .dropdown-menu a{
  display: block;
  color: var(--white);
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 6%;
}
.walletdrop .dropdown-menu a:hover{
  color: var(--violentclr);
}
.profimg{
  width: 32px;
  height: 32px;
  border-radius: 50px;
  object-fit: cover;
}
.innerpages{
  padding-top: 100px;
}
/* body{
  background-color: #090909;
} */
.redclr{
  color: red;
}
.container{
  max-width: 90% !important;
}
.whtclr{
  color: var(--white);
}
.vioclr{
  color: var(--violentclr);
}
.spanvioclr{
  font-size: 18px; 
}
.imgicon{
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.fw-600{font-weight: 600;}
.fw-300{font-weight: 300;}
.pos{
  position: relative;
  z-index: 4;
}
.pos1{
  position: relative;
  z-index: 0;
}
.mup{
  position: relative;
    margin-top: -5%;
}
.bg{
  background: #090909;
  padding: 0px 25px;
}
.fileimg{
  height: 350px;
  min-height: 350px;
  object-fit: cover;
}
.cover{
  opacity: 0;
  z-index: 1;
  top: 4%;
}
.toppos{
  position: absolute;
  left: 2%;
  cursor: pointer !important;
}
.cover1{
z-index: 0;
top: 2%;
}
.edit_sec .cover{
  bottom: 4%;
  top: unset;
}
.edit_sec .toppos{
  right: 2%;
  left: unset;
}
.edit_sec .cover1{
  top: unset;
  bottom: 2%;
}
.memberimg{
  border: 2px solid #8b2fb5;
  border-radius: 50%;
  width: 240px;
  min-width: 240px;
  height: 240px;
  min-height: 240px;
  object-fit: cover;
}
.verify, .verify:hover, .btn.verify:active{
  background: #FF000026;
  color: #FF0000;
  border-radius: 20px;
}
.changebtn, .changebtn:hover, .btn.changebtn:active{
  background: #7C3EBB36;
  color: #7C3EBB;
  border-radius: 20px;
}
.modal-content{
  border: 2px solid var(--modalborder);
  background: var( --modalbackclr);
}
.modal-header {
  border-bottom: transparent;
}
.closebtn{
  cursor: pointer;
  background: #fff;
    display: flex;
    width: 36px;
    font-size: 30px;
    height: 36px;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    position: absolute;
    right: -1%;
    top: -3%;
}
.otpinputs{
  width: 3.5em !important;
  height: 3.5em !important;
  border: 1px solid #4D4D4D;
  background: transparent;
  color: var(--white);
}
.searchinput, .searchinput:focus{
  border: 1px solid #CED4DA;
  background: transparent;
  color: var(--white);
  box-shadow: unset;
  outline: unset;
}
.g_input, .g_input:focus{
  border: 1px solid var(--inputborder);
  background: var(--inputbackclr);
  color: var(--white);
  box-shadow: unset;
  outline: unset;
  height: 50px;
}
textarea.g_input, textarea.g_input:focus {
  height: auto;
  resize: none;
}
.msg_input, .msg_input:focus{
  /* border: 1px solid #292929;
  background:#292929; */
  border: 1px solid #000;
  background:#000;
  color: var(--white);
  box-shadow: unset;
  outline: unset;
  height: 50px;
}
.msg_input::placeholder{
  color: #C4BEBB;
}
.sendbtn, .sendbtn:hover {
  background: var(--violentclr);
  border: 1px solid var(--violentclr);
}
.gamesearch, .gamesearch:focus{
  border: 1px solid #CED4DA;
  background: transparent;
  color: var(--white);
  box-shadow: unset;
  outline: unset;
  height: 50px;
  padding-right: 40px;
}
.gamesearch::placeholder{
  color: #6C757D;
  text-transform: uppercase;
}
.searches{
  position: absolute;
  right: 5%;
  top: 25%;
  color: var(--white);
  font-size: 23px;
}
.g_input.supportinput,.g_input.supportinput:focus{
  border: 1px solid #7B3FB7;
  background: transparent;
} 
.g_input::placeholder{
  color: var(--placeholder);
}
.g_input.supportinput::placeholder{
  color: #969696;
}
.searchinput::placeholder{
  color: #6C757D;
}
.input-group-append .btn-secondary{
  /* border: 1px solid var(--violentclr);
  background: var(--violentclr); */
  border: 1px solid #fff;
  background: var(--violentclr);
  height: 39px;
  border-radius: 0px 4px 4px 0px;
  border-left: 1px solid transparent;
}
.searchinput, .searchinput:focus{
  border-right: 1px solid transparent;
}
.wfull{
  width: 310px;
  min-width: 310px;
}
.tablist{
  display: flex;
  font-size: 17px;
  list-style-type: none;
  padding: 0;
  text-transform: uppercase;
  color: var(--white);
  border-bottom: 1px solid #444444;
  padding-bottom: 1%;
}
.w-25{
  width: 25%;
}
.tablist li{
  margin-left: 3%;
  font-weight: 600;
  cursor: pointer;
}
.tablist li:nth-child(1){
  margin-left: 0%;
}
.tablist li:hover span, .tablist li.active span{
  color: var(--violentclr);
  border-bottom: 2px solid var(--violentclr);
}
.allordertabel th, .allordertabel td{
  color: var(--white);
  font-family: "Poppins", sans-serif !important;
}
.allordertabel thead th{
  background: #0f0f0f !important;
  font-weight: 600 !important;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 12px 20px !important;
}
.allordertabel tbody td{
  font-weight: 300 !important;
  text-transform: capitalize;
  white-space: nowrap;
  padding: 12px 20px !important;
}
.allordertabel .table>:not(caption)>*>* {
  background-color: unset;
  border-bottom-width: 0px;
  padding: 0.9em .5rem;
}
.allordertabel tbody tr:hover{
  background-color: black !important;
  transition: 0.5s;
}
.allordertabel tbody tr:nth-child(odd) {
  background: transparent;
}
.allordertabel tbody tr:nth-child(even) {
  background: #0f0f0f;
}
.cardlist{
  background: var(--cardback);
  border-radius: 6px;
  transition: all 0.5s;
  height: 100%;
  padding: 18px;
}
.cardlist:hover{
  background: var(--primary_Gradient);
}
/* .cardlist:hover{
  transition: 0.5s;
  transform: scale(1.1);
} */

.cardlist:hover .header__connectBtn {
  background-image: none;
  background-color: #090909;
  border-color: #090909;
  color: #fff;
}
.header__connectBtn:disabled{
  opacity: 0.4;
  pointer-events: none;
}
.cardlist .header__connectBtn:hover {
  background-color: #fff;
  border-color: #fff;
  color: #090909;
}



.cardimg{
  width: 100%;
  min-width: 100%;
  height: 310px;
  min-height: 310px;
  object-fit: cover;
  border-radius: 12px;
}
.carddetails{
  background: var(--cardback);
  border-radius: 8px;
}
.carddetails img{
  border-radius: 12px;
}
.sellcard{
  border: 1px solid var(--white);
  border-radius: 10px;
  cursor: pointer;
  height: 230px;
    min-height: 230px;
}
.sellcard:hover h4, .sellcard:hover p{
  color: var(--violentclr);
}
.sellcard:hover{
  border: 1px solid var(--violentclr);
}
.gridall{
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  gap: 30px 10px;
}
.gameimg{
  width: 160px;
  min-width: 160px;
  height: 160px;
  min-height: 160px;
  object-fit: cover;
  aspect-ratio: 3/2;
  margin: auto;
}
.gameborder{
  border: 2px solid transparent;
  transition: 0.5s;
  border-radius: 8px;
  width: 90%;
  margin: auto;
}
.gameborder:hover{
  border: 2px solid var(--violentclr);
  transition: 0.5s;
  transform: scale(1.1);
  filter: drop-shadow(2px 4px 6px #7C3EBB);
}
.upload_drop .dropdown-toggle, .upload_drop .btn:first-child:active{
  border: 1px solid #7B3FB7;
  background: transparent;
  height: 50px;
}
.dropdown-menu{
  border: 1px solid #7B3FB7;
  background: #000;
}
.dropdown-menu .dropdown-item{
  color: var(--white);
}
.dropdown-menu .dropdown-item:hover{
  color: var(--violentclr);
  background-color: transparent;
}
.upload_drop .dropdown-toggle::after {
    position: absolute;
    right: 2%;
    top: 45%;
}
.dragupload{
  border: 1px solid #7B3FB7;
  border-radius: 30px;
  position: relative;
  display: grid;
  place-content: center;
}
.dragupload img {
  margin: auto;
}

.attachname{
  border: 1px solid #7B3FB7;
  border-radius: 8px;
  height: 50px;
  display: flex;
    align-items: center;
    color: #969696;
}
.dragupload input{
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 2;
}
.ratetext{
  font-size: 14px;
  margin-top: 4px;
}
.attach_icon {
  color: var(--violentclr);
    font-size: 24px;
    position: absolute;
    right: 1%;
    top: 25%;
}
.attach_input{
  position: absolute;
    opacity: 0;
    left: 0%;
    width: 100%;
}
.lightvio{
  color: #CE8DFA;
}
.image_card{
  height: 500px;
  min-height: 500px;
  object-fit: contain;
}
.messageborder{
  background: #0F0F0F;
}
.messageborder.readback{
  background-color: #404040c4;
}
.messageborder .dropdown-toggle, .messageborder .btn:first-child:active{
  background: transparent;
  border-color: transparent;
}
.messageborder .dropdown-toggle::after{
  display: none;
}
.micon{
  width: 50px;
  height: 50px;
  border-radius: 50px;
  object-fit: cover;
}
.quill, .ql-snow .ql-stroke, .ql-snow .ql-picker-label::before {
  color: #fff !important;
  stroke: #fff !important;
}
.ql-toolbar.ql-snow{
  /* background-color: #fff; */
}
.instrctborder{
  background-color: #141414;
}
.header__connectBtn.raisebtn{
  border: 1px solid #090909;
  background-color: #090909;
  background-image: unset !important;
}

.assetback{
  background: #141414;
}
.amountborder::after{
  content: "";
  position: absolute;
  background-color: #DEBDFF;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 20px;
  height: 16px;
  min-height: 16px;
}

.progressBar {
  width: 100% !important;
}
/* .assetback ul li span{
  background-color: #DEBDFF !important;
  color: #000;
  font-family: "Poppins", sans-serif !important;
} */
 .widthsets{
  width: 170px;
  height: 170px;
  padding: 10px;
  background: #000;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  bottom: 10%;
  z-index: 0;
 }
 .cursor{
  cursor: pointer;
 }
 .himg{
  object-fit: contain;
  border-radius: 10px;
  width: 170px;
  max-width: 170px;
  height: 100px;
  min-height: 100px;
 }
 .himg1{
  width: 100px;
  max-width: 100px;
 }
 .closesvg{
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 20px;
 }
.fzs{
  font-size: 24px;
}
.attach{
  background-color: var(--violentclr);
  border-radius: 4px 0px 4px 4px;
}
.attach input{
  opacity: 0;
  width: 40px;
  min-width: 40px;
  height: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.attach svg{
  position: absolute;
  left: 15%;
  top: 20%;
  color: #fff;
  font-size: 28px;
  z-index: -1;
}
.labelname{
  color: var(--white) !important;
  font-family: "Poppins", sans-serif !important;
}
.blacktxtimg{
  /* background: url("./Assets/Images/blacktxtimg.png") no-repeat; */
  background-size: 100% 100%;
  width: 400px;
  min-width: 400px;
  /* background: #1a1a1a; */
  background: #272727;
  /* border-radius: 10px; */
  border-radius: 50px 50px 50px 0px;
  position: relative;
  z-index: 3;
}
.viotxtimg{
  /* background: url("./Assets/Images/viotextimg.png") no-repeat; */
  background-size: 100% 100%;
  width: 400px;
  min-width: 400px;
  background: #7d47b3;
  /* border-radius: 10px; */
  border-radius: 50px 50px 0px 50px;
  position: relative;
  z-index: 3;
}
.curveimgs{
  /* position: absolute;
    width: 30px;
    bottom: -10px;
    left: -10px;
    z-index: -1;
    transform: rotate(45deg); */
    position: absolute;
    width: 30px;
    bottom: -9px;
    left: -2px;
    z-index: -1;
    transform: rotate(75deg);
}
.curvevimgs{
  position: absolute;
    width: 30px;
    bottom: -10px;
    right: 0px;
    z-index: -1;
}
.chatbg{
background: #272727;
border-radius: 10px;
}
.backchatbg{
  background: url("./Assets/Images/backchat.png") no-repeat;
  background-size: cover;
  border-radius: 10px;
  width: 100%;
  height: 500px;
  min-height: 500px;
  overflow: auto;
  padding-right: 10px;
}
.css-qbdosj-Input{
  color: #fff !important;
}
.h_auto{
  height: 500px;
  min-height: 500px;
  overflow: auto;
    padding-right: 10px; 
}
.h_auto::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #F5F5F5;
}

.h_auto::-webkit-scrollbar
{
	width: 8px;
	background-color: transparent;
}

.h_auto::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: var(--violentclr);
}
.gap5{
  gap: 48px;
}
.innerpages .cardlistdetail_desc ul, .inst{
  list-style: none;
  padding-left: 0px;
}
.innerpages .cardlistdetail_desc ul li, .inst li{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  flex-direction: row;
  width: 50%;
}
.inst li{
  width: 100% !important;
}
#privy-modal-content h3, #privy-modal-content p{
  color: #fff !important;
}
.innerpages .cardlistdetail_desc ul li .label, .inst li .label{
  color: var(--white);
  font-weight: 600;
  font-size: 17px;
}
.innerpages .cardlistdetail_desc ul li .value, .inst li .value{
  color: var(--white);
  font-weight: 400;
  font-size: 15px;
}
 .reviewlisted{
  /* border: 1px dashed #474747; */
  padding: 20px;
  max-height: 350px;
  overflow: auto;
}
 .reviewlisted ul{
  list-style: none;
  padding-left: 0px;
}
 .reviewlisted ul li{
 display: flex;
 align-items: start;
 justify-content: space-between;

}
.peimg{
  width: 40px;
    height: 40px;
    border-radius: 50px;
    object-fit: cover;
}
 .reviewlisted ul li .name{
  color: var(--white);
  font-size: 16px;
  font-weight: 800;
}
 .reviewlisted ul li .desc{
  color: var(--white);
  font-size: 14px;
}




@media (min-width: 1600px) {
  .spaceman {
    width: 310px;
    bottom: -13%;
    right: 25%;
  }

  .underworld {
    bottom: -32%;
  }
}

@media (min-width: 1800px) {
  .spaceman{
    width: 340px;
    bottom: -16%;
  }
  .underworld {
    bottom: -42%;
}
}
@media (min-width: 1850px) {
  .col-xxxl-2 {
      flex: 0 0 auto;
      width: 20%;
  }
  
   .home__bannerHint{
    font-size: 18px !important;
   }

   
}



@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .underworld{
    bottom: -33%;
   }
   .spaceman{
    right: 25%;
    bottom: -13%;
        width: 280px;
   }
   .home__bannerHint{
    font-size: 17px !important;
   }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
 .underworld{
  bottom: -29%;
 }
 .spaceman{
  right: 25%;
  bottom: -12%;
  width: 250px;
 }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) and (max-height: 650px) { 
  .underworld {
    bottom: -45%;
}
 .spaceman{
  bottom: -18%;
  width: 225px;
 }
}

@media only screen and (max-width: 1199px) {
.gridall{
  grid-template-columns: auto auto auto auto;
}
}

@media only screen and (min-width: 992px) {
.headmenu {
width: 400px;
min-width: 400px;
}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .gap5{
    gap: 30px;
  }
  .underworld{
    bottom: -20%;
   }
   .spaceman{
    right: 30%;
    bottom: -11%;
    width: 220px;
   }
   .stxt{
    font-size: 41px !important;
   }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
.sellcard {
  height: 260px;
  min-height: 260px;
}
.header__linksHolder :is(ul,li){
  font-size: 13px;
}
.gap5{
  gap: 20px;
}
.stxt{
  font-size: 37px !important;
 }
 .spaceman{
  width: 190px;
  right: 32%;
 }
 .underworld{
  bottom: -12%;
 }
 .shake {
  width: 160px;
}
.home_banner {
  padding: 24vh 0px 40vh;
  height: auto;
}
}

@media only screen and (max-width: 991px) {
  .gridall{
    grid-template-columns: auto auto auto;
  }

  .header .container {
    max-width: 98% !important;
  }
  .headinga{
    text-align: center;
  }
  .offcanvas .footer__logo {
    height: auto;
    max-width: 270px;
  }
}



@media (max-width: 767px) {
  .wd{
    width: 250px;
    margin: auto;
    min-width: 250px;
  }
  .sellcard {
    height: unset;
    min-height: unset;
  }
  .gridall{
    grid-template-columns: auto auto;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .underworld {
    bottom: -20%;
  }
  .spaceman {
    width: 120px;
    right: 32%;
}
}

@media (max-width: 575px) {
  .header .container {
    max-width: 100% !important;
  }
  .container{
    max-width: 96% !important;
  }
  .widestar {
    width: 44px;
}
.sec_glitch1,.sec_glitch,.sec_glitch_l {
  width: 100px;
}
  .dinoimg {
    height: 400px;
    min-height: 400px;
}
  .glitchup,.glitch {
    width: 150px;
}
.underworld {
  bottom: -10%;
}
.spaceman {
  width: 100px;
  right: 40%;
}
  .shake {
    width: 110px;
    top: 10%;
}
  .tablist{
    white-space: nowrap;
    overflow: auto;
    padding-bottom: 15px;
  }
  .tablist li {
    margin-left: 7%;
  }
  .cover1{
    top: 4%;
  }
  .fileimg {
    height: 300px;
    min-height: 300px;
  }
.gameimg {
    width: 125px;
    min-width: 125px;
    height: 125px;
    min-height: 125px;
}
.gridall{
  grid-template-columns: auto auto;
  gap: 10px 10px;
}
.gameborder:hover{
  transform: unset;
}
.blacktxtimg, .viotxtimg{
  width: 100%;
  min-width: 100%;
}
.dblock{
  display: block;
  margin-top: 3%;
}
.innerpages {
  padding-top: 80px;
}
.home_banner {
  height: auto;
  padding: 24vh 0 32vh;
}
.gamemenu {
  width: 215px;
}
.attach_icon {
  right: 3%;
}
}

@media (max-width: 374px) {
  .stxt {
    font-size: 25px !important;
}
.wfull {
  width: 260px;
  min-width: 260px;
}
.cardlist h5{
  font-size: 18px;
}
.cardlist .header__connectBtn{
  padding: 7px 12px;
}
}

@media (max-width: 320px) {
  .offcanvas .footer__logo {
    max-width: 230px;
  }
}